@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://cdn.jsdelivr.net/npm/lightgallery@2.4.0/css/lightgallery.css');
@import url('https://cdn.jsdelivr.net/npm/lightgallery@2.4.0/css/lg-zoom.css');
@import url('https://cdn.jsdelivr.net/npm/lightgallery@2.4.0/css/lg-video.css');

.test {
  font-size: 100px;
}

body {
  background: rgb(243, 244, 246);
}

button:hover {
  opacity: 0.9;
}

/* Change autocomplete styles in WebKit */

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  box-shadow: 0 0 0 1000px #fff inset;
  -webkit-box-shadow: 0 0 0 1000px #fff inset;
}

#blue-bg {
  background-color: #001b71;
}

.action {
  font-family: 'Roboto', sans-serif !important;
  font-weight: 700;
  font-size: 20px;
  color: #272727;
}

.info {
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 14px;
  color: #606060;
}

.right-tagline {
  font-family: 'Poppins', sans-serif;
  font-size: 9vmin;
  color: #ffffff;
}

.right-info {
  font-size: 3vmin;
}

.reg-btn-dark {
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
  background-color: #272727;
}

.reg-btn-dark:hover {
  background-color: #001b71;
}

.reg-btn-outline-dark {
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
  color: #272727;
  border: 2px solid #272727;
}

.create-acc {
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-weight: 700;
}

label {
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  font-size: 14px;
}

a {
  color: #5d5fef !important;
  font-family: 'Roboto', sans-serif !important;
  font-weight: 400;
}

:-ms-input-placeholder {
  font-size: 14px;
  font-family: 'Roboto', sans-serif !important;
}

::placeholder {
  font-size: 14px;
  font-family: 'Roboto', sans-serif !important;
}

:-moz-placeholder {
  font-size: 14px;
  font-family: 'Roboto', sans-serif !important;
}

::-webkit-input-placeholder {
  font-size: 14px;
  font-family: 'Roboto', sans-serif !important;
}

.FixWhatsappIcon label {
  display: flex;
  align-items: flex-start;
}

.FixWhatsappIcon label > input {
  margin-top: 0.325rem;
}

.otp {
  display: flex;
  justify-content: center;
  font-size: 3.5rem;
  font-family: 'Roboto', sans-serif;
  font-weight: normal;
  width: 2.5ch;
  height: 2.5ch;
  border: 1px solid lightgray;
  border-radius: 1vh;
  padding: 0 5px;
}

.otp::placeholder {
  text-align: center;
  font-size: 3.5rem;
  color: lightgray;
}

.splitter {
  color: lightgray;
}

.profile-subnav div.active {
  font-weight: 700;
  box-shadow: inset 0 -2px 0 #272727;
  color: rgba(39, 39, 39, 0.9);
}

.gallery-content {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
}

th.active .sort-down {
  color: #000;
}

.overview-card {
  width: 10.1rem;
}

.slick-slide > div {
  margin: 0 16px;
}

/* .slick-list {
  margin: 0 -24px;
} */
.live-event-card {
  width: 9.8rem;
}

.main-slider .alice-carousel__stage-item {
  width: 22% !important;
}

.main-slider .alice-carousel__stage-item > div {
  width: 100% !important;
}

.main-slider .alice-carousel__stage-item:nth-child(1) {
  margin-right: 100px !important;
}

.main-slider .alice-carousel__stage-item:nth-child(2) {
  width: 33% !important;
  margin: 0 50px !important;
}

.india.react-multi-carousel-list:nth-child(2) {
  min-width: 100px;
  width: 100px;
}

/* .live-event-card:before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0) 100%),
    linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6) 68.75%);
  border-radius: 8px;

} */

.attraction-img-wrapper::before,
.live-event-card::before,
.card-bg::before,
.carousel-item::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.attraction-img-wrapper::before {
  background: linear-gradient(180deg, rgba(39, 39, 39, 0) 31.69%, rgba(39, 39, 39, 0.9) 61.84%);
  /* background: linear-gradient(180deg, rgba(39, 39, 39, 0) -0.53%, rgba(39, 39, 39, 0.9) 54.21%); */
}

.live-event-card::before,
.gallery-detail::before,
.card-bg::before {
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.6) 0%,
    rgba(0, 0, 0, 0) 40%,
    rgba(0, 0, 0, 0) 60%,
    rgba(0, 0, 0, 0.6) 100%
  );
}

.card-bg::before {
  border-radius: 0.5rem;
}

.attraction-card {
  max-width: 21.43rem;
}

.video-slider .slick-slide > div {
  transform: scaleY(0.9) scaleX(1);
  transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

.video-slider .slick-center > div {
  transform: scale(1);
}

.video-slider .carousel-item > img {
  width: 100%;
  height: auto;
}

@media screen and (max-width: 640px) {
  .slick-slide > div {
    transform: scaleY(0.9) scaleX(1);
    transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  }

  .slick-center > div {
    transform: scale(1);
  }

  .carousel-item > img {
    width: 100%;
    height: auto;
  }

  .mobile-padding {
    padding: 4px !important;
  }

  .summary-donut {
    width: 320px;
  }

  /*
  .stats-c {
    width: 106%;
    transform: translateX(-3%);
  }
  */

  .medal-item-coach {
    width: 7.6rem;
  }

  .flex-items {
    flex-direction: column !important;
  }
}

@media screen and (min-width: 640px) {
  .summary-donut {
    width: 427px;
  }
}

td div.active {
  display: flex;
}

.horizontal-scroll {
  overflow-x: auto;
}

/* .horizontal-scroll::-webkit-scrollbar {
  width: 0px;
} */

.verified-icon {
  min-width: 18px;
  min-height: 19px;
}

@media screen and (min-width: 768px) and (max-width: 1380px) {
  .summary-donut {
    width: 100% !important;
  }
}

@media screen and (min-width: 1416px) {
  .attraction-card {
    width: 31.25%;
    max-width: 25.1rem;
  }
}

@media screen and (max-width: 1430px) {
  .summary-donut {
    width: 454px !important;
  }

  .overview-data {
    height: 250px !important;
    width: 250px !important;
  }

  .attraction-card:nth-child(5),
  .attraction-card:nth-child(4) {
    max-width: 39.375rem;
  }
}

/* tablets */

@media screen and (min-width: 768px) {
  div.select-section {
    order: 2;
  }

  div.export-btn {
    order: 3;
  }

  .action {
    font-size: 32px;
  }

  .overview-card {
    width: 16.4rem;
  }

  /*
  .live-event-card {
    width: 34%;
  }
  .live-event-card:nth-child(1) {
    width: 40%;
  }
  .live-event-card:nth-child(2) {
    width: 55%;
  }
  .live-event-card:nth-child(3) {
    width: 50%;
  }
  .live-event-card:nth-child(4) {
    width: 46%;
  }
  .live-event-card:nth-child(5) {
    width: 21%;
  }
  */
  /* .attractions-container:last-child {
    width: 46%;
  } */
  .attraction-card {
    max-width: 19rem;
  }

  .carousel-item,
  .react-multi-carousel-item {
    width: 80%;
  }

  .main-slider .alice-carousel__stage-item:nth-child(2) {
    width: 50% !important;
  }

  .main-slider .alice-carousel__stage-item:nth-child(1) {
    margin-right: 0 !important;
  }

  .main-slider .alice-carousel__stage-item:nth-child(2) > div {
    width: 100% !important;
  }
}

/* mid screens */

@media screen and (min-width: 1000px) {
  .op-op {
    max-width: 67vw;
  }

  .overview-data {
    width: 50% !important;
  }

  .attraction-card:nth-child(5),
  .attraction-card:nth-child(4) {
    width: 48%;
    max-width: 48%;
  }

  .attraction-card {
    max-width: 21.43rem;
  }

  /*
  .live-event-card {
      width: 37.5%;
  }

  .live-event-card:nth-child(2) {
      width: 57%;
  }
  */
}

/* large screens */

@media screen and (min-width: 1416px) {
  .attraction-card {
    max-width: 25rem;
  }
}

@media screen and (min-width: 1380px) {
  .op-op {
    max-width: 74vw;
  }
}

.blueBadge {
  padding: 2.9px 5.8px;
  background-color: #ececfd;
  color: #5d5fef;
  border-radius: 2.9px;
}

.active_menu {
  transform: translateX(0%);
}

.animate-button {
  animation: scale 0.2s both;
}

@keyframes scale {
  50% {
    transform: scale(0.9);
  }
  100% {
    transform: scale(1);
  }
}

.input-file-container label:before {
  content: url('./assets/img/file.svg');
  width: 14px;
  height: 14px;
  margin-right: 6px;
  margin-top: 3px;
}

.inputfile {
  z-index: -1;
  height: 0.1px;
  width: 0.1px;
}

.choices__button {
  display: none;
}

.field-required:after {
  z-index: 0 !important;
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.bg-pattern {
  background: url('assets/img/pattern.png') no-repeat -5%,
    conic-gradient(from 51.11deg at 50% 58.75%, #8e2de2 0deg, #4a00e0 360deg);
}

.bg-dull-pattern {
  background: url('assets/img/pattern.png') no-repeat -5%,
    conic-gradient(
      from 51.11deg at 50% 58.75%,
      rgba(221, 221, 221, 1),
      rgba(228, 228, 228, 1) 360deg
    );
}

.no-scrollbar {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

[ref='fileLink'] {
  pointer-events: none;
  cursor: default;
  text-decoration: none;
  color: black;
}

.btn.btn-primary.btn-md {
  width: 100%;
}

.basis-1\/12 {
  flex-basis: 8%;
}

.basis-2\/12 {
  flex-basis: 16%;
}

.basis-3\/12 {
  flex-basis: 24%;
}

.basis-4\/12 {
  flex-basis: 33%;
}

.basis-5\/12 {
  flex-basis: 41%;
}

.basis-6\/12 {
  flex-basis: 49%;
}

.basis-7\/12 {
  flex-basis: 58%;
}

.basis-8\/12 {
  flex-basis: 66%;
}

.basis-9\/12 {
  flex-basis: 74%;
}

.basis-10\/12 {
  flex-basis: 83%;
}

.basis-11\/12 {
  flex-basis: 91%;
}

.basis-12\/12 {
  flex-basis: 100%;
}
